// src/components/Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import './Header.css';

const Header = ({ user, auth, signOut }) => {  // userを受け取る
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const navigateToList = () => {
    navigate('/list');
  };

  return (
    <header className="header">
      <div className="header-left">
        <Logo className="logo" onClick={navigateToList} />
      </div>
      <div className="header-right">
        {user && (  // ユーザーがログインしている場合のみ表示
          <button className="logout-link" onClick={handleLogout}>ログアウト</button>
        )}
      </div>
    </header>
  );
}

export default Header;
