import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const LoginView = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;

      // ドメインが @archetyp.jp であることを確認
      if (!email.endsWith('@archetyp.jp')) {
        await auth.signOut();
        navigate('/');
        alert('許可されていないユーザーです。');
        return;
      }

      // Firestoreでユーザーが既に存在するかを確認
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        // ユーザーが存在しない場合、新規登録
        await setDoc(userDocRef, {
          role: 'user', // 一般ユーザーとして登録
          created_at: new Date(),
          modified_at: new Date()
        });
        console.log('新規ユーザーがFirestoreに登録されました:', user.email);
      }

      // ログイン後のリダイレクト
      navigate('/list');
    } catch (error) {
      console.error('ログイン中にエラーが発生しました:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-8">ARCHETYP稼働管理</h1>
      <button
        onClick={handleGoogleLogin}
        className="px-6 py-3 bg-primary text-white rounded-md hover:bg-primary-dark"
      >
        Googleでログイン
      </button>
    </div>
  );
};

export default LoginView;
