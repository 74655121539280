import { initializeApp } from 'firebase/app';
import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// 他の必要なFirebaseサービスをインポート

const firebaseConfig = {
  apiKey: "AIzaSyAKOZVaIuK0-fZPTeV7uUbD345RjesSKT0",
  authDomain: "archetyp-wms.firebaseapp.com",
  projectId: "archetyp-wms",
  storageBucket: "archetyp-wms.appspot.com",
  messagingSenderId: "9148990755",
  appId: "1:9148990755:web:04c0685d77e7b7d2c53896",
  measurementId: "G-C8SWLZX660"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// 他の必要なFirebaseサービスの初期化

export { app, auth, db, firebaseSignOut as signOut };
