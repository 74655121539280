import React, { useState, useRef, useEffect } from 'react';
import { Input } from './ui/input';

const FilterableSelect = ({ options = [], value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRef = useRef([]);

  const handleInputFocus = () => {
    setIsOpen(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setHighlightedIndex(-1);
  };

  const filteredOptions = options.flatMap(group => {
    const groupOptions = group.options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return groupOptions.length > 0 ? [{ ...group, options: groupOptions }] : [];
  });

  const allOptions = filteredOptions.flatMap(group => group.options);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    const handleKeyDown = (e) => {
      if (!isOpen) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setHighlightedIndex((prev) => (prev < allOptions.length - 1 ? prev + 1 : prev));
          break;
        case 'ArrowUp':
          e.preventDefault();
          setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : 0));
          break;
        case 'Enter':
          e.preventDefault();
          if (highlightedIndex !== -1) {
            const selectedOption = allOptions[highlightedIndex];
            onChange(selectedOption.value);
            setIsOpen(false);
            setSearchTerm('');
          }
          break;
        case 'Escape':
          setIsOpen(false);
          setSearchTerm('');
          break;
        default:
          break;
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, highlightedIndex, filteredOptions, onChange]);

  useEffect(() => {
    if (highlightedIndex !== -1 && optionsRef.current[highlightedIndex]) {
      optionsRef.current[highlightedIndex].scrollIntoView({
        block: 'nearest',
      });
    }
  }, [highlightedIndex]);

  return (
    <div className="relative" ref={containerRef} style={{ minWidth: '200px' }}>
      <div
        className="w-full p-2 border rounded flex justify-between items-center cursor-pointer"
        onClick={handleInputFocus}
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-controls="options-list"
        style={{ minWidth: '200px' }}
      >
        <span>{allOptions.find(opt => opt.value === value)?.label || placeholder}</span>
        <span>▼</span>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg" style={{ minWidth: '600px' }}>
          <Input
            ref={inputRef}
            type="text"
            placeholder="業務を検索..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="w-full p-2"
          />
          <ul id="options-list" role="listbox" className="max-h-60 overflow-auto">
            {filteredOptions.length === 0 && (
              <li className="p-2 text-gray-500">該当する業務がありません</li>
            )}
            {filteredOptions.map((group, groupIndex) => (
              <React.Fragment key={group.label}>
                <li className="p-2 font-bold text-gray-400 text-sm">{group.label}</li>
                {group.options.map((option, index) => {
                  const flatIndex = filteredOptions.slice(0, groupIndex).reduce((acc, cur) => acc + cur.options.length, 0) + index;
                  return (
                    <li
                      key={option.value}
                      ref={(el) => (optionsRef.current[flatIndex] = el)}
                      className={`p-2 cursor-pointer ${
                        highlightedIndex === flatIndex ? 'bg-blue-100' : 'hover:bg-gray-100'
                      }`}
                      onClick={() => {
                        onChange(option.value);
                        setIsOpen(false);
                        setSearchTerm('');
                      }}
                      role="option"
                      aria-selected={highlightedIndex === flatIndex}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterableSelect;
