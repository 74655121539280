// src/App.js
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginView from './components/LoginView';
import RegisterView from './components/RegisterView';
import ListView from './components/ListView';
import { auth, signOut } from './firebase-config';  // signOutをインポート
import LoadingSpinner from './components/LoadingSpinner';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingSpinner loading={loading} />;
  }

  return (
    <Router>
      <Header user={user} auth={auth} signOut={signOut} />  {/* userを渡す */}
      <Routes>
        <Route path="/" element={user ? <Navigate to="/list" /> : <LoginView />} />
        <Route path="/register" element={user ? <RegisterView /> : <Navigate to="/" />} />
        <Route path="/list" element={user ? <ListView /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
