import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { Button } from './ui/button';
import { Input } from './ui/input';
import FilterableSelect from './FilterableSelect';
import { collection, getDocs, query, orderBy, where, doc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase-config';
import { FaEdit, FaTrash, FaSave, FaTimes } from 'react-icons/fa';
import { fetchJobs } from "../services/fetchJobs";

const ListView = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [listEntries, setListEntries] = useState([]);
  const [displayedEntries, setDisplayedEntries] = useState(50);
  const [editingRow, setEditingRow] = useState(null);
  const [editedEntry, setEditedEntry] = useState({});
  const [deletingEntry, setDeletingEntry] = useState(null);
  const [taskOptions, setTaskOptions] = useState([]);
  const [errors, setErrors] = useState({});

  const fetchJobsData = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) {
      navigate("/");
      return;
    }

    const now = new Date();
    const daysAgo30 = new Date();
    daysAgo30.setDate(now.getDate() - 30);
        // JavaScriptの日付を"YYYY-MM-DD"形式の文字列に変換
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const formattedDaysAgo30 = formatDate(daysAgo30);
    console.log(daysAgo30, formattedDaysAgo30);

    const queryConstraints = [
      where("users", "array-contains", user.email),
      where("billing_date", ">=", formattedDaysAgo30 ), // billing_dateが30日前以降
      orderBy("account"),
      orderBy("name"),
      orderBy("billing_date"),
    ];

    try {
      const taskOptions = await fetchJobs("listViewJobs", queryConstraints);
      setTaskOptions(Object.keys(taskOptions).map((account) => ({
        label: account,
        options: taskOptions[account],
      })));
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  }, [navigate]);

  const fetchWorks = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/');
      return;
    }

    try {
      const worksCollection = collection(db, 'works');
      const worksQuery = query(worksCollection, where('user_id', '==', user.uid), orderBy('date', 'desc'), orderBy('created_at', 'desc'));
      const querySnapshot = await getDocs(worksQuery);

      const works = [];
      querySnapshot.forEach((doc) => {
        works.push({
          id: doc.id,
          ...doc.data()
        });
      });

      setListEntries(works);
    } catch (error) {
      console.error('Error fetching works:', error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchJobsData();
    fetchWorks();
  }, [fetchJobsData, fetchWorks]);

  const handleEdit = (entry) => {
    setEditingRow(entry.id);
    setEditedEntry({ ...entry });
  };

  const handleSave = async (id) => {
    const newErrors = {};
    if (!editedEntry.date) {
      newErrors.date = '日付を入力してください';
    }
    if (!editedEntry.job_id) {
      newErrors.job_id = '業務を選択してください';
    }
    if (!editedEntry.time) {
      newErrors.time = '稼働時間を入力してください';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/');
        return;
      }

      // job_idに対応するjob_nameを取得
      const selectedJobOption = taskOptions
        .flatMap(optionGroup => optionGroup.options)
        .find(option => option.value === editedEntry.job_id);
      const jobName = selectedJobOption ? selectedJobOption.label : '';

      // データベースを更新
      const workRef = doc(db, `works/${id}`);
      await updateDoc(workRef, { 
        ...editedEntry, 
        job_name: jobName,  // job_nameを追加
        modified_at: serverTimestamp() 
      });

      // ローカルのリストも更新
      setListEntries(listEntries.map(entry =>
        entry.id === id ? { ...entry, ...editedEntry, job_name: jobName } : entry
      ));
      setEditingRow(null);
      setEditedEntry({});
      setErrors({});

    } catch (error) {
      console.error('Error updating work:', error);
    }
};


  const handleCancel = () => {
    setEditingRow(null);
    setEditedEntry({});
    setErrors({});
  };

  const handleChange = (field, value) => {
    setEditedEntry(prev => ({ ...prev, [field]: value }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleDelete = (entry) => {
    setDeletingEntry(entry);
  };

  const confirmDelete = async () => {
    try {
      if (deletingEntry) {
        const user = auth.currentUser;
        if (!user) {
          navigate('/');
          return;
        }

        const workRef = doc(db, `works/${deletingEntry.id}`);
        await deleteDoc(workRef);

        setListEntries(listEntries.filter(entry => entry.id !== deletingEntry.id));
        setDeletingEntry(null);
      }
    } catch (error) {
      console.error('Error deleting work:', error);
    }
  };

  const cancelDelete = () => {
    setDeletingEntry(null);
  };

  const loadMore = () => {
    setDisplayedEntries(prev => Math.min(prev + 50, listEntries.length));
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thTdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  };

  const dateColumnStyle = {
    ...thTdStyle,
    width: '120px',
  };

  const jobColumnStyle = {
    ...thTdStyle,
    minWidth: '200px',
    width: 'auto',
  };

  const contentColumnStyle = {
    ...thTdStyle,
    minWidth: '200px',
    width: 'auto',
  };

  const timeColumnStyle = {
    ...thTdStyle,
    width: '100px',
  };

  const actionColumnStyle = {
    ...thTdStyle,
    width: '120px',
    textAlign: 'center',
  };

  const containerStyle = {
    width: '100%',
    overflowX: 'auto',
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">稼働一覧</h2>
        <div>
          <Button 
            onClick={() => navigate('/register')} 
            className="bg-primary hover:bg-primary-dark text-white"
          >
            新規登録
          </Button>
        </div>
      </div>
      <div style={containerStyle}>
        <table style={tableStyle}>
          <thead>
            <tr style={{ backgroundColor: '#f8fafc' }}>
              <th style={dateColumnStyle}>日付</th>
              <th style={jobColumnStyle}>業務</th>
              <th style={contentColumnStyle}>内容</th>
              <th style={timeColumnStyle}>時間</th>
              <th style={actionColumnStyle}>操作</th>
            </tr>
          </thead>
          <tbody>
            {listEntries.slice(0, displayedEntries).map((entry) => (
              <tr key={entry.id}>
                {editingRow === entry.id ? (
                  <>
                    <td style={dateColumnStyle}>
                      <Input
                        type="date"
                        value={editedEntry.date || ''}
                        onChange={(e) => handleChange('date', e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      {errors.date && <div className="text-red-500 mt-1">{errors.date}</div>}
                    </td>
                    <td style={jobColumnStyle}>
                      <FilterableSelect
                        options={taskOptions}
                        value={editedEntry.job_id || ''}
                        onChange={(value) => handleChange('job_id', value)}
                        placeholder="業務を選択"
                      />
                      {errors.job_id && <div className="text-red-500 mt-1">{errors.job_id}</div>}
                    </td>
                    <td style={contentColumnStyle}>
                      <Input
                        value={editedEntry.description || ''}
                        onChange={(e) => handleChange('description', e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    </td>
                    <td style={timeColumnStyle}>
                      <Input
                        type="number"
                        value={editedEntry.time || ''}
                        onChange={(e) => handleChange('time', e.target.value)}
                        min="0"
                        step="0.25"
                        onKeyDown={handleKeyDown}
                      />
                      {errors.time && <div className="text-red-500 mt-1">{errors.time}</div>}
                    </td>
                    <td style={actionColumnStyle}>
                      <div className="flex justify-center space-x-2">
                        <Button onClick={() => handleSave(entry.id)} className="bg-green-500 hover:bg-green-600 text-white">
                          <FaSave />
                        </Button>
                        <Button onClick={handleCancel} className="bg-gray-500 hover:bg-gray-600 text-white">
                          <FaTimes />
                        </Button>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td style={dateColumnStyle}>{entry.date || '未設定'}</td>
                    <td style={jobColumnStyle}>{entry.job_name || '未設定'}</td>
                    <td style={contentColumnStyle}>{entry.description || '未設定'}</td>
                    <td style={timeColumnStyle}>{entry.time || '0'}時間</td>
                    <td style={actionColumnStyle}>
                      <div className="flex justify-center space-x-2">
                        <Button onClick={() => handleEdit(entry)} className="bg-yellow-500 hover:bg-yellow-600 text-white">
                          <FaEdit />
                        </Button>
                        <Button onClick={() => handleDelete(entry)} className="bg-red-500 hover:bg-red-600 text-white">
                          <FaTrash />
                        </Button>
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {displayedEntries < listEntries.length && (
        <Button onClick={loadMore} className="w-full mt-4 bg-primary hover:bg-primary-dark text-white">
          もっと読み込む
        </Button>
      )}
      {deletingEntry && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
            <h3>削除の確認</h3>
            <p>この項目を削除してもよろしいですか？この操作は取り消せません。</p>
            <Button onClick={confirmDelete} className="mr-2 bg-red-500 hover:bg-red-600 text-white">削除</Button>
            <Button onClick={cancelDelete} className="bg-gray-500 hover:bg-gray-600 text-white">キャンセル</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListView;

                       
