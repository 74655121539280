import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingSpinner = ({ loading }) => {
  return (
    <div className={`flex justify-center items-center h-screen bg-gray-100 ${loading ? 'fixed inset-0 z-50' : 'hidden'}`}>
      <ClipLoader size={150} color={"#123abc"} loading={loading} />
    </div>
  );
};

export default LoadingSpinner;
